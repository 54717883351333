window.addEventListener('load', () => {
  Utils.init();
  setCommonEvent();
});

/**
 * 공통함수
 */
const Utils = {
  /**
   * Set default event
   */
  init: () => {
    Utils.setDatepicker();
    Utils.setTableCheckbox();
    // Utils.setValidation();
    Utils.setCustomDropdownSelect();
    Utils.setWizardDropdownSelect();
    Utils.setWizardBtnEvent();
    Utils.setFilterEvent();
    Utils.setToastr();
    Utils.setCustomListEvent();
    Utils.setJstreeEvent();
    Utils.setTooltip();
    Utils.setSelectAreaEvent();
    Utils.setSelect2();
    Utils.setFileUpload();
    Utils.setSelectAreaModalEvent();
    Utils.setActiveWizardTab();
    Utils.setCustomTable();
    Utils.setWeatherEvent();
  },
  /**
   * globar variables
   */
  global: {},
  /**
   * Check Device and hide
   */
  // checkDevice() {
  //   var isMobile = /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson|LG|SAMSUNG|Samsung/i.test(navigator.userAgent);
  //   if (isMobile && Utils.getBreakpoint() == 'sm') {

  //   } else {

  //   }
  // },
  /**
   * Set Weather Scroll Event
   */
  setWeatherEvent() {
    $('.weather-tab .nav-link').on('click', function () {
      const $container = $(this).closest('.weather-container');
      const $table = $container.find('.weather-table');
      const index = $(this).parents().index();
      const totalCol = $container.find('.weather-table-row:first-of-type .weather-table-data');
      const $target = $container.find(`.weather-table-row:first-of-type .weather-table-data:nth-last-of-type(${index == 0 ? totalCol.length - 1 : 48 / index + 1})`);
      const stickyColWidth = $container.find('.weather-table-row:first-of-type .weather-table-data:first-of-type').outerWidth();
      const duration = Math.abs(($target.position().left - stickyColWidth) - $table[0].scrollLeft);

      $table.stop().animate({ 'scrollLeft': $target.position().left - stickyColWidth }, duration / 6);

      if (Utils.global['weather'] != null) clearInterval(Utils.global['weather']);

      Utils.global['weather'] = setTimeout(() => {
        Utils.global['weather'] = null;
      }, duration / 6);
    });
    $('.weather-table').on('scroll', function () {
      const $container = $(this).closest('.weather-container');
      const $table = $container.find('.weather-table');
      const $tabs = $container.find('.weather-tab .nav-item');
      const totalCol = $container.find('.weather-table-row:first-of-type .weather-table-data');

      if (!Utils.global['weather']) {
        $tabs.each(function () {
          const index = $(this).index();
          const $target = $container.find(`.weather-table-row:first-of-type .weather-table-data:nth-last-of-type(${index == 0 ? totalCol.length - 1 : 48 / index + 1})`);
          const stickyColWidth = $container.find('.weather-table-row:first-of-type .weather-table-data:first-of-type').outerWidth();

          if ($table[0].scrollLeft >= $target.position().left - stickyColWidth) {
            $tabs.find('.nav-link').removeClass('active');
            $(this).find('.nav-link').addClass('active');
          }
        });
      }
    });
  },
  /**
   * 
   * @param {String} id 
   * @param {Array} data 
   * @param {String} unit 
   * @param {String} color 
   */
  setLineChart(id, data, unit, color) {
    var chartDom = document.querySelector(id);
    var table = $(chartDom).closest('.weather-table')[0];
    $(chartDom).css('width', table.scrollWidth - 296);

    var myChart = echarts.init(chartDom);
    var option = {
      tooltip: {
        show: false,
      },
      grid: {
        top: 52,
        left: -56,
        right: -56,
        bottom: 0,
      },
      xAxis: {
        type: 'category',
        axisLabel: false,
        boundaryGap: false,
        data: data,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false
        },
        axisLine: {
          onZero: false
        },
      },
      yAxis: {
        type: 'value',
        min: -8,
        axisLabel: false,
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false
        },
      },
      series: [
        {
          label: {
            show: true,
            position: 'top',
            offset: [0, -12],
            textStyle: {
              fontFamily: 'Pretendard, sans-serif',
              fontSize: '18',
              color: '#596063',
              fontWeight: '600',
            },
            rich: function (data) {
              console.log(data);
            },
            formatter: function (data) {
              return data.value + unit;
            }
          },
          symbol: 'circle',
          silent: true,
          lineStyle: {
            color: color,
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: color,
            color: color,
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(-10, -10, -10, 1, [
              {
                offset: 0,
                color: color
              },
              {
                offset: 1,
                color: '#fff'
              }
            ]),
          },
          data: data,
          type: 'line',
        }
      ]
    };
    option && myChart.setOption(option);
  },
  /**
   * Login Step move event
   * ex) Utils.goStep('#step_1', '#step_2');
   * @param {String} startId
   * @param {String} targetId
   */
  goStep(startId, targetId) {
    if (startId == targetId) {
      return;
    }
    const $container = $(startId).closest('.login-main');
    const $containerInner = $(startId).closest('.login-main-inner');
    const $currentSection = $(startId).closest('section');
    const $target = $(targetId);

    $target.css('display', 'block');
    if ($target.index() > 0) {
      $container.find('h1').css('display', 'none');
    } else {
      $container.find('h1').css('display', 'block');
    }
    if ($target.index() > $currentSection.index()) {
      $containerInner.css('transform', `translateX(-${$container.outerWidth()}px)`);
    } else {
      $containerInner.addClass('no-animate');
      $containerInner.css('transform', `translateX(-${$container.outerWidth()}px)`);
      setTimeout(function () {
        $containerInner.removeClass('no-animate');
        $containerInner.css('transform', 'translateX(0)');
      }, 10);
    }
    setTimeout(function () {
      $containerInner.addClass('no-animate');
      $currentSection.css('display', 'none');
      $containerInner.css('transform', 'translateX(0)');
      setTimeout(function () {
        $containerInner.removeClass('no-animate');
      }, 10);
    }, 300);
  },
  /**
   * Set Dashboard Swiper
   */
  setSwiper(target) {
    var swiper = new Swiper(target, {
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      spaceBetween: 16,
      pagination: {
        el: target + ' .swiper-pagination',
      },
    });
    return swiper;
  },
  /**
   * Set Project Section
   */
  /**
   * Set  Timer
   * ex) reset => Utils.setCodeTimer('#step_9');, end => Utils.setCodeTimer('#step_9', true);
   * @param {String} targetId
   * @param {Boolean} isEnd
   */
  setCodeTimer(targetId, isEnd) {
    const $target = $(targetId);

    if (Utils.global['timer'] != null) clearInterval(Utils.global['timer']);

    if (isEnd) {
      $target.find('input').attr('disabled', true);
      $target.find('hr').addClass('d-none');
      $target.find('.hide').removeClass('d-none');
    } else {
      $target.find('.count-container .count').text('3:00');
      Utils.setRefresh();

      $target.find('input').val('').attr('disabled', false);
      $target.find('.count-wrap').removeClass('text-warning');
      $target.find('.custom-invalid-feedback').css('display', 'none');
      $target.find('hr').removeClass('d-none');
      $target.find('.hide').addClass('d-none');

      setTimeout(function () {
        let minute = 3;
        let second = 0;

        $target.find('.number-list input').eq(0).trigger('focus');

        Utils.global['timer'] = setInterval(function () {
          if (second == 0) {
            if (minute == 0) {
              clearInterval(Utils.global['timer']);
              $target.find('input').attr('disabled', true);
              $target.find('.count-wrap').addClass('text-warning');
              $target.find('.custom-invalid-feedback').css('display', 'block');
            } else {
              minute = minute - 1;
              second = 59;
            }
          } else {
            second--;
          }

          let currentTime = minute + ':' + (second < 10 ? '0' + second : second);
          $target.find('.count-container .count').text(currentTime);
        }, 1000);
      }, 300);
    }
  },
  /**
   * Set Alert for Refresh
   */
  setRefresh() {
    let fnUnload = function (e) {
      e.preventDefault();
      return (e.returnValue = '');
    };
    $(window).on('beforeunload', fnUnload);
  },
  /**
   * Set Select Area Modal Event
   */
  setSelectAreaModalEvent() {
    $(document).on('click', '.btn-select-area-modal', function () {
      const $modal = $($(this).attr('data-target'));
      const $pagination = $modal.find('.select-area-pagination');
      const $current = $pagination.find('input.current');

      $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
      $modal.addClass('active');
      $modal.addClass('visible');

      if ($current.val() == Number($current.attr('min'))) {
        $pagination.find('.page-prev').attr('disabled', true);
      }
      if ($current.val() == Number($current.attr('max'))) {
        $pagination.find('.page-next').attr('disabled', true);
      }

      setTimeout(() => {
        $('body').addClass('modal-open');
        $('.header-backdrop').addClass('show');
      }, 100);
    });
    $(document).on('click', '.select-area-modal .btn-close', function () {
      $('.header-backdrop').removeClass('show');
      $('.select-area-modal').removeClass('active');
      $('body').removeClass('modal-open');
      setTimeout(() => {
        $('.header-backdrop').remove();
        $('.select-area-modal').removeClass('visible');
      }, 300);
    });
    $(document).on('change', '.select-area-pagination input', function () {
      if ($(this).val() < Number($(this).attr('min'))) {
        $(this).val($(this).attr('min'));
      }
      if ($(this).val() > Number($(this).attr('max'))) {
        $(this).val($(this).attr('max'));
      }
    });
    $(document).on('click', '.select-area-pagination button[class^="page-"]', function () {
      const $pagination = $(this).closest('.select-area-pagination');
      const $current = $pagination.find('input.current');

      if ($(this).hasClass('page-prev')) {
        $current.val(Number($current.val()) - 1);
        $pagination.find('.page-next').attr('disabled', false);
      } else if ($(this).hasClass('page-next')) {
        $current.val(Number($current.val()) + 1);
        $pagination.find('.page-prev').attr('disabled', false);
      }

      if ($current.val() == Number($current.attr('min'))) {
        $pagination.find('.page-prev').attr('disabled', true);
      } else if ($current.val() == Number($current.attr('max'))) {
        $pagination.find('.page-next').attr('disabled', true);
      }
    });
    $(document).on('click', '.select-area-modal .select-area-item', function () {
      const $modal = $(this).closest('.select-area-modal');
      const $section = $(this).find('.section');
      const $button = $(`[data-target="#${$modal.attr('id')}"]`);
      const $targetInput = $button.closest('.custom-group').find('.custom-inner input');
      $targetInput.val(`${$section.text()} 구획`);

      $modal.find('.btn-close').trigger('click');
    });
  },
  /**
   * Set Custom File Upload
   */
  setFileUpload() {
    // #region Set Drag and Drop File
    // $(document).on('dragenter', '.file-container', function() {
    //   $(this).addClass('active');
    // });
    // $(document).on('dragleave drop', '.file-container input[type="file"]', function() {
    //   const $fileContainer = $(this).closest('.file-container');
    //   $fileContainer.removeClass('active');
    // });
    // $(document).on('change', '.file-container input[type="file"]', function () {
    //   let elmnt = $(this)[0];
    //   let acceptList = $(this).attr('accept').replaceAll('.', '').replaceAll(' ', '').split(',');
    //   let file = Utils.getFileDetail(elmnt);
    //   let isValid = false;

    //   if (file.size) {
    //     for(let i = 0; i < acceptList.length; i++) {
    //       if(acceptList[i] == 'exe' || acceptList[i] == file.ext) {
    //         isValid = true;
    //         break;
    //       }
    //     }
    //   }

    //   if(!isValid) {
    //     toastr.error('업로드가 불가능한 파일형식입니다.');
    //   }
    // });
    // #endregion Set Drag and Drop File
    // #region Set Default File
    $(document).on('change', '.file-group input[type="file"]', function () {
      let $parent = $(this).closest('.file-group');
      let $fileWrap = $parent.find('.file-wrap');
      let placeholder = $parent.attr('data-placeholder');
      let elmnt = $(this)[0];
      let file = Utils.getFileDetail(elmnt);

      if (file.size) {
        $parent.find('.btn-remove').addClass('active');
        $fileWrap.html(`
        <i class="material-icons">file_present</i>
        <div class="name">${file.name}</div>
        <div class="ext">.${file.ext}</div>
        `);
      } else {
        $parent.find('.btn-remove').removeClass('active');
        $fileWrap.html(`
        <p class="placeholder">${placeholder}</p>
        `);
      }
    });
    $(document).on('click', '.file-group .btn-remove', function () {
      let $parent = $(this).closest('.file-group');
      let $fileInput = $parent.find('input[type="file"]');
      $fileInput.val('').trigger('change');
    });
    // #endregion Set Default File
    // #region Set Default Custom input
    $(document).on('click', '.custom-group .btn-remove', function () {
      let $parent = $(this).closest('.custom-group');
      let $fileInput = $parent.find('input');
      $fileInput.val('').trigger('change');
      $(this).removeClass('active');
    });
    // #endregion Set Default Custom input
  },
  /**
   * Set Select2
   */
  setSelect2() {
    const $select2List = $('select.select2');
    let options = {
      allowClear: true,
      language: {
        noResults: function noResults() {
          return '검색 결과가 없습니다.';
        },
      },
    };
    $select2List.select2(options);

    // $select2List.each(function () {
    //   let placeholder = $(this).attr('data-placeholder');
    //   if (placeholder != null && placeholder != '' && $(this).val() == null) {
    //     options['placeholder'] = placeholder;
    //     $(this).val('');
    //   }
    // });
    $(document).on('select2:open', 'select.select2', function () {
      const $optionList = $(this).find('option[value!=""]');
      $optionList.each(function (index) {
        setTimeout(function () {
          $('.select2-results__option').eq(index).wrapInner('<span class="value"></span>');
        }, 10);
      });
      // Just Dropdown
      if ($(this).hasClass('dropdown')) {
        $('.select2-dropdown').closest('.select2-container').addClass('dropdown');
      }
    });
    $(document).on('input', '.select2-search__field', function () {
      const resultOptionList = $('.select2-results__option');
      if (!resultOptionList.hasClass('select2-results__message')) {
        resultOptionList.each(function () {
          $(this).wrapInner('<span class="value"></span>');
        });
      }
    });
  },
  /**
   * 시설정보 조회 구획 Data 가져오기
   */
  getSelectArea() {
    let data = [];
    let $selectAreaInputList = $('.select-area-result .select-area-item input[name="selectArea"]');
    $selectAreaInputList.each(function () {
      data.push(JSON.parse($(this).val()));
    });
    return data;
  },
  /**
   * 시설정보 조회 구획 선택
   */
  addSelectArea(data) {
    let $areaItem = $(`.area-item[data-id="${data['fcltySn']}_${data['zoneSn']}"]`);
    let $selectAreaResult = $('.select-area-result');
    let $selectAreaEmpty = $selectAreaResult.find('.empty');
    let $selectContainer = $('.select-container');

    if (!$areaItem.hasClass('selected')) {
      $selectAreaEmpty.remove();
      $areaItem.addClass('selected');

      let newSelectAreaItem = `
      <div class="select-area-item" data-id="${data['fcltySn']}_${data['zoneSn']}">
        ${data['fcltyNm']} (${data['zoneSn']}구역)<button type="button" class="btn-icon btn-remove" onclick="Utils.removeSelectArea('${data['fcltySn']}_${data['zoneSn']}')"><i class="material-icons">close</i></button>
      </div>
      `;

      $selectAreaResult.append(newSelectAreaItem);
      let $newSelectAreaItem = $(`.select-area-item[data-id="${data['fcltySn']}_${data['zoneSn']}"]`);
      let newDataInput = document.createElement('input');
      newDataInput.type = 'hidden';
      newDataInput.name = 'selectArea';
      newDataInput.value = JSON.stringify(data);
      $newSelectAreaItem.append(newDataInput);

      $selectContainer.find('.selected').text($selectAreaResult.find('.select-area-item').length);
    } else {
      Utils.removeSelectArea(`${data['fcltySn']}_${data['zoneSn']}`);
    }
  },
  /**
   * 시설정보 조회 구획 삭제
   */
  removeSelectArea(dataId) {
    let $areaItem = $(`.area-item[data-id="${dataId}"]`);
    let $selectAreaItem = $(`.select-area-item[data-id="${dataId}"]`);
    let $selectAreaResult = $selectAreaItem.closest('.select-area-result');
    let $selectContainer = $selectAreaResult.siblings('.select-container');

    if ($selectAreaItem.siblings().length == 0) {
      $selectAreaResult.append(`<div class="empty">${$selectAreaResult.attr('data-placeholder')}</div>`);
    }

    $areaItem.removeClass('selected');

    $selectAreaItem.remove();

    $selectContainer.find('.selected').text($selectAreaResult.find('.select-area-item').length);
  },
  /**
   * 시설정보 조회 구획 전체 초기화
   */
  removeAllSelectArea() {
    let $selectAreaResult = $('.select-area-result');
    let $selectAreaItemList = $selectAreaResult.find('.select-area-item');

    $selectAreaItemList.each(function () {
      let targetId = $(this).attr('data-id');
      Utils.removeSelectArea(targetId);
    });
  },
  /**
   * Set Custom Table
   */
  setCustomTable() {
    // select2 열었을때 custom data value 유지되도록
    $(document).on('select2:open', '.custom-table select.select2', function () {
      const $optionList = $(this).find('option[value!=""]');
      $optionList.each(function (index) {
        const $this = $(this);
        if ($this.attr('data-selected') == 'true') {
          setTimeout(function () {
            $('.select2-results__option').eq(index).addClass('selected');
            $('.select2-results__option').eq(index).find('.custom-badge').remove();
            $('.select2-results__option').eq(index).append('<span class="custom-badge"><i class="material-icons mr-4">done</i>선택</span>');
          }, 10);
        }
      });
    });
    $(document).on('select2:select', '.custom-table select.select2', function (e) {
      const $table = $(this).closest('.custom-table');
      const $select2List = $table.find(`select.select2 option[value="${e.params.data.id}"]`);
      $select2List.attr('data-selected', true);
    });
    $(document).on('select2:unselect', '.custom-table select.select2', function (e) {
      const $this = $(this);
      const $table = $(this).closest('.custom-table');
      const $select2List = $table.find(`select.select2 option[value="${e.params.data.id}"]`);
      $select2List.attr('data-selected', false);
      setTimeout(function () {
        $this.select2('close');
      }, 10);
    });
    // select2 검색할때 custom data value 유지되도록
    $(document).on('input', '.select2-search__field', function () {
      const resultOptionList = $('.select2-results__option');
      if (!resultOptionList.hasClass('select2-results__message')) {
        const selectId = $(this).attr('aria-controls').replace('select2-', '').replace('-results', '');
        const $select = $('#' + selectId);
        const $optionList = $select.find('option[value!=""]');
        resultOptionList.each(function () {
          const text = $(this).text();
          const isSelected = $optionList
            .filter(function () {
              return $(this).text() == text;
            })
            .attr('data-selected');
          if (isSelected == 'true') {
            $(this).addClass('selected');
            $(this).find('.custom-badge').remove();
            $(this).append('<span class="custom-badge"><i class="material-icons mr-4">done</i>선택</span>');
          }
        });
      }
    });
  },
  /**
   * Set Select Area Event
   */
  setSelectAreaEvent() {
    // remove select area click event
    $(document).on('click', '.search-area-result .search-area-item', function () {
      $(this).siblings().removeClass('active');
      $(this).addClass('active');
    });
    // remove select area click event
    $(document).on('click', '.select-area-result .select-area-item .btn-remove', function () {
      let $selectAreaItem = $(this).closest('.select-area-item');
      Utils.removeSelectArea($selectAreaItem.attr('data-id'));
    });
  },
  /**
   * Set Toastr option
   * ex)
   * Success: toastr.success('title');
   * Info: toastr.info('title');
   * Warning: toastr.warning('title');
   * Error: toastr.error('title');
   */
  setToastr() {
    toastr.options = {
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      // progressBar: true,
      hideDuration: 500,
    };
  },
  /**
   * Add Custom Button Binding
   */
  setJstreeEvent() {
    $('.jstree').on('ready.jstree create_node.jstree refresh.jstree rename_node.jstree delete_node.jstree open_node.jstree', function (e, data) {
      const nodeList = data.instance._model.data;
      for (let node in nodeList) {
        if (nodeList[node].id != '#' && nodeList[node].original.buttons != null) {
          const $nodeEl = $(`#${nodeList[node].id}`);
          const buttons = nodeList[node].original.buttons;
          const buttonEvents = nodeList[node].original.buttonEvents;
          // 이미 버튼이 있는 경우 삭제 후 redraw
          // $nodeEl.find('> button').remove();
          $nodeEl.find('> .btn-wrap').remove();
          $nodeEl.find('> .jstree-anchor').after('<div class="btn-wrap"></div>');
          for (let i = 0; i < buttons.length; i++) {
            // $nodeEl.find('> .jstree-anchor').after(buttons[i]);
            $nodeEl.find('> .jstree-anchor + .btn-wrap').append(buttons[i]);

            let $currentButton = $nodeEl.find('> .jstree-anchor + .btn-wrap button').last();
            if (buttonEvents != null) {
              $currentButton.on('click', buttonEvents[i]);
            }
          }
        }
      }
    });
  },
  /**
   * Set List Filter Event
   */
  setCustomListEvent() {
    // jstree
    $(document).on('input', '.custom-search', function () {
      const $target = $($(this).attr('data-target'));
      $target.jstree(true).search($(this).val());
    });
  },
  /**
   * Set List Filter Event
   */
  setFilterEvent() {
    // search focus event
    $(document).on('focusin', '.search-container .search-main select, .search-container .search-main input', function () {
      $(this).closest('.search-main').addClass('focus');
    });
    $(document).on('focusout', '.search-container .search-main select, .search-container .ta-search-wrap input', function () {
      $(this).closest('.search-main').removeClass('focus');
    });
    $(document).on('change', '.search-container .search-main select', function () {
      $(this).closest('.search-main').removeClass('focus');
    });

    $(document).on('click', '.filter-wrap > label', function (e) {
      e.preventDefault();
      e.stopPropagation();

      $(this).prev().find('.btn').trigger('click');
    });
    $(document).on('click', '.filter-wrap .dropdown-menu, .ui-datepicker, .ui-datepicker-prev, .ui-datepicker-next, .select2-search__field', function (e) {
      e.stopPropagation();
    });
    $(document).on('click', '.filter-wrap .btn-filter-reset', function () {
      let $parent = $(this).parents('.button-group');
      let $dropdown = $(this).parents('.dropdown');
      let $dropdownMenu = $(this).parents('.dropdown-menu');

      if ($dropdown.data('type') == 'multi' || $dropdown.data('type') == 'single') {
        let $selectItemList = $dropdownMenu.find('input:checked');
        $selectItemList.prop('checked', false);
        $parent.find('.btn-filter-apply').trigger('click');
      } else if ($dropdown.data('type') == 'date') {
        let $selectItemList = $dropdownMenu.find('input');
        $selectItemList.val('');
        $parent.find('.btn-filter-apply').trigger('click');
        $selectItemList.val(`${Utils.dateFormatter(new Date()).dash} ~ ${Utils.dateFormatter(new Date()).dash}`).trigger('keyup');
      } else if ($dropdown.data('type') == 'date-pair') {
        let $startDate = $dropdownMenu.find('.start input');
        let $endDate = $dropdownMenu.find('.end input');
        $startDate.val('').trigger('change');
        $endDate.val('').trigger('change');
        $parent.find('.btn-filter-apply').trigger('click');
        // $startDate.val(Utils.dateFormatter(new Date()).dash);
        // $endDate.val(Utils.dateFormatter(new Date()).dash);
      } else if ($dropdown.data('type') == 'keyword') {
        let $selectItemList = $dropdownMenu.find('input');
        $selectItemList.val('');
        $parent.find('.btn-filter-apply').trigger('click');
      } else if ($dropdown.data('type') == 'range') {
        let $selectItemList = $dropdownMenu.find('input');
        $selectItemList.val('');
        $parent.find('.btn-filter-apply').trigger('click');
      } else if ($dropdown.data('type') == 'select2') {
        let $selectItemList = $dropdownMenu.find('select');
        $selectItemList.val('').trigger('change');
        $parent.find('.btn-filter-apply').trigger('click');
      }

      $('.filter-wrap .dropdown').removeClass('show');
      $('.filter-wrap .dropdown-menu').removeClass('show');
    });
    $(document).on('click', '.list-control .btn-all-reset', function () {
      const $listControl = $(this).closest('.list-control');
      $listControl.find('.btn-filter-reset').trigger('click');
    });
    $(document).on('click', '.filter-wrap .btn-filter-apply', function () {
      let $dropdown = $(this).parents('.dropdown');
      let $dropdownMenu = $(this).parents('.dropdown-menu');
      let $btnFilterReset = $dropdownMenu.find('.btn-filter-reset');
      let selectLabel = $dropdown.next().text();

      $dropdown.removeClass('dropdown-placeholder');
      $dropdown.addClass('active');

      if ($dropdown.data('type') == 'multi' || $dropdown.data('type') == 'single') {
        let $selectItemList = $dropdownMenu.find('input:checked');

        if ($selectItemList.length == 1) {
          $btnFilterReset.attr('disabled', false);
          let selectTextSub = $selectItemList.next().find('.sub').text();
          let selectText = $selectItemList.next().text().replace(selectTextSub, '');
          $dropdown.find('> .btn').text(selectLabel + ': ' + selectText);
        } else if ($selectItemList.length > 1) {
          $btnFilterReset.attr('disabled', false);
          let selectText = '';
          $selectItemList.each(function (index, el) {
            if (index > 0) selectText += ', ';
            selectText += $(this).next().text();
          });
          $dropdown.find('> .btn').text(`${selectLabel + ': ' + selectText}`);
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      } else if ($dropdown.data('type') == 'date') {
        let $selectItem = $dropdownMenu.find('.custom-daterangepicker');

        if ($selectItem.val() != '') {
          $btnFilterReset.attr('disabled', false);
          $dropdown.find('> .btn').text(selectLabel + ': ' + $selectItem.val());
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      } else if ($dropdown.data('type') == 'date-pair') {
        let $startDate = $dropdownMenu.find('.start input');
        let $endDate = $dropdownMenu.find('.end input');

        if ($startDate.val() != '' || $endDate.val() != '') {
          $btnFilterReset.attr('disabled', false);
          $dropdown.find('> .btn').text(selectLabel + ': ' + $startDate.val() + ' ~ ' + $endDate.val());
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      } else if ($dropdown.data('type') == 'keyword') {
        let $selectItem = $dropdownMenu.find('input');

        if ($selectItem.val() != '') {
          $btnFilterReset.attr('disabled', false);
          $dropdown.find('> .btn').text(selectLabel + ': ' + $selectItem.val());
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      } else if ($dropdown.data('type') == 'range') {
        let $selectItemList = $dropdownMenu.find('input');

        if ($selectItemList.eq(1).val() != '' && Number($selectItemList.eq(0).val()) > Number($selectItemList.eq(1).val())) {
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          toastr.error(`${$dropdown.next().text()} 값을 확인해주세요.`);
          return;
        }

        if ($selectItemList.eq(0).val() != '' || $selectItemList.eq(1).val() != '') {
          $btnFilterReset.attr('disabled', false);
          $dropdown.find('> .btn').text(selectLabel + ': ' + $selectItemList.eq(0).val() + ' ~ ' + $selectItemList.eq(1).val());
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      } else if ($dropdown.data('type') == 'select2') {
        let $selectItem = $dropdownMenu.find('select option:selected');

        if ($selectItem.val() != '') {
          $btnFilterReset.attr('disabled', false);
          $dropdown.find('> .btn').text(selectLabel + ': ' + $selectItem.text());
        } else {
          $btnFilterReset.attr('disabled', true);
          $dropdown.addClass('dropdown-placeholder');
          $dropdown.removeClass('active');
          $dropdown.find('> .btn').text($dropdown.next().text());
        }
      }

      $('.filter-wrap .dropdown').removeClass('show');
      $('.filter-wrap .dropdown-menu').removeClass('show');
      $dropdown.trigger('custom.hide.bs.dropdown');
    });
    $(document).on('hide.bs.dropdown', '.filter-wrap .dropdown', function (e) {
      $(this).parent().find('.btn-filter-apply').trigger('click');
      $('.ui-datepicker').css('display', 'none');
    });
  },
  /**
   * Set Active Wizard Tab For Refresh
   */
  setActiveWizardTab(target) {
    const currentUrl = window.location.href;
    let newUrl = '';

    if (currentUrl.includes('tab=')) {
      newUrl = currentUrl.split('tab=')[0];
      newUrl += 'tab=' + target;
    } else {
      if (currentUrl.includes('?')) {
        newUrl = currentUrl + '&tab=' + target;
      } else {
        newUrl = currentUrl + '?tab=' + target;
      }
    }

    if (target) {
      //url 변경
      history.replaceState(null, null, newUrl);
    } else {
      $(`.wizard-custom-tab .nav-link[href="${currentUrl.split('tab=')[1]}"]`).trigger('click');
    }
  },
  /**
   * Set Wizard Button Event
   */
  setWizardBtnEvent() {
    // prev button click event
    $(document).on('click', '.wizard-prev', function () {
      let $parent = $(this).parents('.wizard-pane');
      let $wizardTab = $(`a[href='#${$parent.attr('id')}'`).parent();
      let $wizardTabPrev = $wizardTab.prev().find('a');
      $wizardTabPrev.trigger('click');
    });
    // next button click event
    $(document).on('click', '.wizard-next', function () {
      let $parent = $(this).parents('.wizard-pane');
      let $wizardTab = $(`a[href='#${$parent.attr('id')}'`).parent();
      let $wizardTabNext = $wizardTab.next().find('a');
      $wizardTabNext.trigger('click');
    });
    // tab click event
    $('.wizard-custom-tab .nav-item').on('click', function (e) {
      let $parent = $(this).closest('.wizard-custom-tab');
      let $this = $(this);
      let $target = $($(this).find('.nav-link').attr('href'));
      let $navLink = $(this).find('.nav-link');
      if ($navLink.hasClass('active')) {
        return;
      }
      if ($parent.hasClass('custom-event')) {
        $target.siblings().html('');
      }

      Utils.setActiveWizardTab($navLink.attr('href'));

      setTimeout(function () {
        let $parent = $this.parents('.wizard-custom-tab');
        let $mobileTab = $parent.next();
        $mobileTab.find(`.dropdown-item[data-href="${$navLink.attr('href')}"]`).trigger('click');
      }, 100);
    });
  },
  /**
   * Set Wizard Select
   */
  setWizardDropdownSelect(tagId = null) {
    const createWizardDropdownSelect = function (el) {
      let $originalSelect = $(el);
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      // let placeholder = '선택해주세요.';
      let placeholder = typeof common === 'undefined' ? '선택해주세요.' : common.getMsg('usr.msg.plzSelect');
      let isPlaceholder = false;
      if ($(el).parent().find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" data-toggle="dropdown" aria-expanded="false">
        ${placeholder}
        </button>
        <div class="dropdown-menu" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {
              if ($originalOptionList[j].value == '') {
                isPlaceholder = true;
                placeholder = $originalOptionList[j].textContent;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}">
                ${$originalOptionList[j].text}
                <span class="valid-badge"></span>
                </a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            if ($originalOptionList[i].value == '') {
              isPlaceholder = true;
              placeholder = $originalOptionList[i].textContent;
            } else {
              newSelect += `
              <a href="javascript:void(0)"
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-value="${$originalOptionList[i].value}" data-href="${$($originalOptionList[i]).data('href')}">
              ${$originalOptionList[i].text}
              <span class="valid-badge"></span>
              </a>
              `;
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);
        $originalSelect.next().find('.btn').text(placeholder);

        if ($(el).parent().find('a.dropdown-item.active').length > 0) {
          $('#' + $originalSelect.attr('id') + 'Dropdown').attr('data-value', $(el).parent().find('a.dropdown-item.active').data('value'));
          $('#' + $originalSelect.attr('id') + 'Dropdown').attr('data-href', $(el).parent().find('a.dropdown-item.active').data('href'));
          $('#' + $originalSelect.attr('id') + 'Dropdown').html($(el).parent().find('a.dropdown-item.active').html());
        } else {
          if (isPlaceholder) {
            $(el).parent().find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    };
    if (tagId == null) {
      let $customSelect = $('.wizard-custom-mobile-tab select:not(:disabled)');
      $(document).off('click', '.wizard-custom-mobile-tab .custom-dropdown-select .dropdown-item');
      $(document).on('click', '.wizard-custom-mobile-tab .custom-dropdown-select .dropdown-item', function (e) {
        let $this = $(this);
        let $originalSelect = $this.parents('.wizard-custom-mobile-tab').find('select');
        let $originalOptionList = $originalSelect.find('option:not([value=""]');
        let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
        let $btn = $this.parents('.custom-dropdown-select').find('.btn');
        let $navLink = $(`.wizard-custom-tab [href="${$this.data('href')}"]`);

        let selectedIndex = $($navLink.attr('href')).index();
        let $tabItems = $($navLink.attr('href')).parent().find('.wizard-pane');
        let isValid = true;
        let isSaved = true;

        if (!$navLink.hasClass('active')) {
          $tabItems.each(function (index, el) {
            if (index < selectedIndex) {
              if ($(this).hasClass('required')) {
                let $requiredInputList = $(this).find('input:required, textarea:required, select:required');
                let $tabLink = $('[href="#' + $(el).attr('id') + '"]');
                isValid = getInputValid($requiredInputList, true);
                isSaved = !$(el).hasClass('required') || $tabLink.parent().hasClass('on');

                if (!isValid) {
                  e.stopPropagation();
                  return false;
                } else {
                  if (!isSaved) {
                    e.stopPropagation();
                    // alert(`${$tabLink.text().trim()} 데이터를 저장해주세요.`);
                    // toastr.error(`${$tabLink.text().trim()} 데이터를 저장해주세요.`);
                    let transMsg = typeof common === 'undefined' ? '데이터를 저장해주세요.' : common.getMsg('usr.msg.plzSaveData');
                    toastr.error(`${$tabLink.text().trim()} ${transMsg}`);
                    $btn.trigger('click');

                    return false;
                  }
                }
              }
            }
          });
        }

        if (isValid && isSaved) {
          $originalOptionList.eq($index).prop('selected', true);
          $btn.attr('data-value', $this.attr('data-value'));
          $btn.html($this.html());
          $btn.parent().removeClass('dropdown-placeholder');
          $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
          $this.addClass('active');
          $originalSelect.removeClass('is-invalid');
          $originalSelect.closest('div').parent().find('.invalid-feedback').remove();
          $navLink.trigger('click');
        }
      });
      $customSelect.each((index, el) => {
        createWizardDropdownSelect(el);
      });
    } else {
      let $originalSelect = $(`#${tagId}`);
      $originalSelect.parent().find('.custom-dropdown-select').remove();
      createWizardDropdownSelect($(`#${tagId}`)[0]);
    }
  },
  /**
   * Set Default Tabulator
   * @param {Tabulator} table
   */
  setTabulator(table) {
    table.on('renderComplete', function () {
      var id = '#' + $(this.element).attr('id');
      let $tableSizeSelectOptionList = $(id).find('.tabulator-page-size option');
      // for select custom
      $tableSizeSelectOptionList.each(function () {
        $(this).text($(this).text().replace('개씩 보기', '') + '개씩 보기');
      });
      // for checkbox custom
      let $checkbox = $(id).find('input[type="checkbox"]');
      // custom checkbox
      $checkbox.each(function (index, el) {
        $(this).addClass('sr-only');
        if (table.options.selectable) {
          $(this).parent().addClass('ta-checkbox-wrap');
        } else {
          $(this).parent().addClass('ta-radio-wrap');
        }
        $(this).attr('id', `${id.replace('#', '')}_${index}`);
        $(this).parent().find('label').remove();
        $(this).parent().append(`<label for="${id.replace('#', '')}_${index}">&nbsp;</label>`);
      });
      $('.tabulator .btn-collapse').off().on('click', function () {
        const $originalBtnCollapse = $(this).prev().find('.tabulator-responsive-collapse-toggle');
        $originalBtnCollapse.trigger('click');
        if ($originalBtnCollapse.hasClass('open')) {
          $(this).addClass('collapsed');
        } else {
          $(this).removeClass('collapsed');
        }
      });
      let columns = table.columnManager.columns;
      for (let i = 0; i < columns.length; i++) {
        let el = $(columns[i].contentElement).find('.tabulator-col-title')[0];
        if (Utils.getScrollYN(el)) {
          $(el).attr('data-toggle', 'tooltip');
          $(el).attr('title', $(el).text());
        }
      }
    });
    table.on('tableBuilt', function () {
      // reset checkbox list
      table.getSelectedRows().forEach(function (row) {
        row.deselect();
      });
    });
    table.on('rowSelectionChanged', function (data, rows) {
      if (rows.length > 0) {
        const $parent = $(rows[0]._row.parent.element);
        const $allCheckbox = $parent.siblings('.tabulator-header').find('input[type="checkbox"]');
        const $eachCheckboxList = $parent.find('input[type="checkbox"]');
        if (rows.length == $eachCheckboxList.length) {
          $allCheckbox.prop('indeterminate', false).prop('checked', true);
        }
      }
    });
  },
  /**
   * Set Default Tabulator
   * @param {tui.Grid} table
   */
  setTuigrid(grid) {
    if (grid != null) {
      grid.on('onGridMounted', (e) => {
        let $columns = $(e.instance.el).find('.tui-grid-rside-area .tui-grid-cell-header');
        $columns.each(function () {
          if (Utils.getScrollYN($(this)[0])) {
            $($(this)[0]).attr('data-toggle', 'tooltip');
            $($(this)[0]).attr('title', $($(this)[0]).text());
          }
        });
      });
      grid.on('onGridUpdated', (e) => {
        let $columns = $(e.instance.el).find('.tui-grid-rside-area .tui-grid-cell-header');
        $columns.each(function () {
          if (Utils.getScrollYN($(this)[0])) {
            $($(this)[0]).attr('data-toggle', 'tooltip');
            $($(this)[0]).attr('title', $($(this)[0]).text());
          }
        });
      });
    }
  },
  /**
   * Set Datepicker
   */
  setDatepicker() {
    let $datepicker = $('.datepicker');
    $datepicker.datepicker({
      dateFormat: 'yy-mm-dd',
      // modal에서 position 틀어지는 부분 잡기
      beforeShow: function (input, inst) {
        const $modal = $(input).closest('.modal');
        if ($modal.length > 0) {
          $modal.append($('#ui-datepicker-div'));
        }

        var calendar = inst.dpDiv;

        setTimeout(function () {
          calendar.position({
            my: 'right top',
            at: 'right bottom',
            collision: 'none',
            of: input
          });
        }, 1);
      }
    });
    // $datepicker.datepicker().datepicker('setDate', 'today');

    // 최소, 최대 일자 선택
    $('.start .datepicker').on('change', function () {
      let $parent = $(this).closest('.ta-date-pair-wrap');
      let $endDate = $parent.find('.end .datepicker');
      let val = $(this).val();
      $endDate.datepicker('option', 'minDate', val);
    });
    $('.end .datepicker').on('change', function () {
      let $parent = $(this).closest('.ta-date-pair-wrap');
      let $startDate = $parent.find('.start .datepicker');
      let val = $(this).val();
      $startDate.datepicker('option', 'maxDate', val);
    });
    $datepicker.trigger('change');

    // daterangepicker
    // let $daterangepicker = $('.custom-daterangepicker');
    // $daterangepicker.each(function () {
    //   const d = new Date();

    //   // 오늘날의 년, 월, 일 데이터
    //   const day = d.getDate();
    //   const month = d.getMonth();
    //   const weekAgo = new Date(new Date().setDate(day - 7));
    //   const monthAgo = new Date(new Date().setMonth(month - 1));

    //   $(this).daterangepicker({
    //     autoUpdateInput: false,
    //     // startDate: new Date(monthAgo),
    //     endDate: new Date(),
    //     locale: {
    //       format: 'YYYY-MM-DD',
    //       separator: ' ~ ',
    //       applyLabel: '확인',
    //       cancelLabel: '취소',
    //     },
    //     ranges: {
    //       오늘: [new Date(), new Date()],
    //       '7일 전': [weekAgo, new Date()],
    //       '30일 전': [monthAgo, new Date()],
    //     },
    //   });
    //   $(this).on('apply.daterangepicker', function (ev, picker) {
    //     $(this).val(picker.startDate.format('YYYY-MM-DD') + ' ~ ' + picker.endDate.format('YYYY-MM-DD'));
    //   });
    //   $(this).on('focus', function () {
    //     $('[data-range-key="Custom Range"]').text('맞춤');
    //   });
    //   if ($(this).parents('.list-control').length > 0) {
    //     // 목록 필터영역일 경우 default가 오늘
    //     $(this).data('daterangepicker').setStartDate(new Date());
    //     $(document).on(
    //       'click',
    //       `
    //     .daterangepicker .ranges ul li,
    //     .table-condensed th.prev,
    //     .table-condensed th.next,
    //     .daterangepicker .drp-buttons .cancelBtn,
    //     .daterangepicker .drp-buttons .applyBtn`,
    //       function (e) {
    //         e.stopPropagation();
    //       },
    //     );
    //   } else {
    //     // 나머지 daterangepicker default는 한달전
    //     $(this).data('daterangepicker').setStartDate(monthAgo);
    //     $(this).val(Utils.dateFormatter(monthAgo).dash + ' ~ ' + Utils.dateFormatter(new Date()).dash);
    //   }
    // });
  },
  /**
   * Set Tooltip
   */
  setTooltip() {
    $(document).off('mouseenter', '[data-toggle="tooltip"]');
    $(document).on('mouseenter', '[data-toggle="tooltip"]', function () {
      $(this).tooltip({
        content: function () {
          return $(this).prop('title');
        },
      });
      $(this).tooltip('open');
    });
    $(document).off('click', '.btn-popover-call');
    $(document).on('click', '.btn-popover-call', function () {
      const $popover = $(this).closest('.popover-container').find('.popover-custom');
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
        $popover.fadeIn(300);
      } else {
        $popover.fadeOut(300);
      }
    });

    $(document).on('click', '.close-btn', function () {
      // '.btn-popover' 버튼 찾기
      const $btnPopover = $(this).closest('.popover-container').find('.btn-popover-call');
      // 버튼의 'active' 클래스 토글
      $btnPopover.toggleClass('active');
      // 연결된 '.popover-custom'의 표시 상태 변경
      const $popover = $btnPopover.closest('.popover-container').find('.popover-custom');
      if ($btnPopover.hasClass('active')) {
        $popover.fadeIn(300);
      } else {
        $popover.fadeOut(300);
      }
    });

    $(document).on('click', '.btn-popover-naver-talk', function () {
      window.open('https://talk.naver.com/ct/w5z6l5?frm=pmd', '_blank');
    });



    $(document).ready(function () {
      function elementInView(el, dividend = 1) {
        const elementTop = $(el).offset().top;
        return (
          elementTop <= ($(window).height() / dividend + $(window).scrollTop())
        );
      }

      function displayScrollElement(element) {
        $(element).addClass('visible');
      }

      function handleScrollAnimation() {
        $('.construction-photo').each(function () {
          if (elementInView(this, 1.25)) {
            displayScrollElement(this);
          }
        });
      }

      $(window).on('scroll', function () {
        handleScrollAnimation();
      });

      // 초기 실행
      handleScrollAnimation();
    });
  },


  /**
   * Set Table Checkbox
   */
  setTableCheckbox() {
    $(document).on('change', '.ta-table-wrap input.all', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $eachCheckboxList = $tableWrap.find('input.each');
      $eachCheckboxList.prop('checked', $(e.target).prop('checked'));

      if ($(e.target).prop('checked')) {
        $eachCheckboxList.closest('tr').addClass('active');
      } else {
        $eachCheckboxList.closest('tr').removeClass('active');
      }
    });
    $(document).on('change', '.ta-table-wrap input.each', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $allCheckbox = $tableWrap.find('input.all');
      let $eachLength = $tableWrap.find('input.each').length;
      let $eachCheckedLength = $tableWrap.find('input.each:checked').length;

      if ($(e.target).prop('checked')) {
        $(e.target).closest('tr').addClass('active');
      } else {
        $(e.target).closest('tr').removeClass('active');
      }

      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
    // $(document).on('click', '.tui-grid-container input.all + label', (e) => {
    //   let $tableContainer = $(e.target).parents('.tui-grid-container');
    //   let $allTable = $tableContainer.find('.tui-grid-table');

    //   if($(e.target).siblings('input').prop('checked')) {
    //     $allTable.removeClass();
    //     $allTable.addClass('tui-grid-table');
    //   } else {
    //     $allTable.removeClass();
    //     $allTable.addClass('tui-grid-table active-all');
    //   }
    // });
    // $(document).on('change', '.tui-grid-container input.each', (e) => {
    //   let $tableContainer = $(e.target).parents('.tui-grid-container');
    //   let $allTable = $tableContainer.find('.tui-grid-table');
    //   let index = $(e.target).closest('td').attr('data-row-key');

    //   if($(e.target).prop('checked')) {
    //     $allTable.addClass(`active-${index}`);
    //     $allTable.removeClass(`default-${index}`);
    //   } else {
    //     $allTable.removeClass(`active-${index}`);
    //     $allTable.addClass(`default-${index}`);
    //   }
    // });
  },
  /**
   * Set Validation
   */
  setValidation() {
    let elements = document.querySelectorAll('input, textarea');
    for (let i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity(' ');
        if (!e.target.validity.valid) {
          elements[i].classList.add('is-invalid');
          if (e.target.type == 'file') {
            // label.classList.remove('btn-primary');
            // label.classList.add('btn-danger');
          }
        } else {
          if (e.target.type != 'file') {
            elements[i].classList.remove('is-invalid');
          } else {
            // label.classList.add('btn-primary');
            // label.classList.remove('btn-danger');
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity(' ');
      };
      if ($(elements[i]).attr('required')) {
        elements[i].focusout = () => {
          // elements[i].classList.remove('is-valid');
          elements[i].classList.remove('is-invalid');
          if (elements[i].value == null || elements[i].value == '') {
            elements[i].classList.add('is-invalid');
          } else {
            // elements[i].classList.add('is-valid');
          }
        };
      }
    }
  },
  /**
   * Set Custom Select
   * if Change Select Call setCustomDropdownSelect(id);
   * @param {String} tagId
   */
  setCustomDropdownSelect(tagId = null) {
    const createCustomDropdownSelect = function (el) {
      let $originalSelect = $(el);
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      // let placeholder = '선택';
      let placeholder = typeof common === 'undefined' ? '선택' : common.getMsg('ui.msg.select');

      let isPlaceholder = false;
      if (!$originalSelect.attr('disabled') && $(el).parent().find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" ${$originalSelect.attr('disabled') ? 'tabindex="-1"' : ''
          } data-toggle="dropdown" aria-expanded="false" data-boundary="viewport">
        ${placeholder}
        </button>
        <div class="dropdown-menu" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {
              if ($originalOptionList[j].value == '') {
                isPlaceholder = true;
                placeholder = $originalOptionList[j].textContent;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}"
                data-toggle="tooltip" data-placement="bottom"
                title="${$originalOptionList[j].title}">
                ${$originalOptionList[j].text}
                </a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            if ($originalOptionList[i].value == '') {
              isPlaceholder = true;
              placeholder = $originalOptionList[i].textContent;
            } else {
              newSelect += `
              <a href="javascript:void(0)"
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-toggle="tooltip" data-placement="bottom"
              data-value="${$originalOptionList[i].value}"
              title="${$originalOptionList[i].title}">
              ${$originalOptionList[i].text}</a>
              `;
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);
        $originalSelect.next().find('.btn').text(placeholder);

        if ($(el).parent().find('a.dropdown-item.active').length > 0) {
          $('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).parent().find('a.dropdown-item.active').text());
        } else {
          if (isPlaceholder) {
            $(el).parent().find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    };
    if (tagId == null) {
      let $customSelect = $('.ta-custom-dropdown-select-wrap > select:not(:disabled)');
      $(document).off('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item');
      $(document).on('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item', function () {
        let $this = $(this);
        // let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
        let $originalSelect = $(`#${$this.closest('.dropdown-menu').attr('aria-labelledby')}`).parents('.ta-custom-dropdown-select-wrap').find('select');
        let $originalOptionList = $originalSelect.find('option:not([value=""]');
        let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
        let $btn = $this.parents('.custom-dropdown-select').find('.btn');
        $originalOptionList.eq($index).prop('selected', true);
        $originalSelect.trigger('change');
        $btn.text($this.text());
        $btn.parent().removeClass('dropdown-placeholder');
        $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
        $this.addClass('active');
        $originalSelect.removeClass('is-invalid');
        $originalSelect.closest('div').parent().find('.invalid-feedback').remove();
      });
      $customSelect.each((index, el) => {
        createCustomDropdownSelect(el);
      });
    } else {
      let $originalSelect = $(`#${tagId}`);
      $originalSelect.parent().find('.custom-dropdown-select').remove();
      createCustomDropdownSelect($(`#${tagId}`)[0]);
    }
  },
  /**
   * Get CSS Breakpoint
   */
  getBreakpoint() {
    let breakpoint = getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content');
    breakpoint = breakpoint.replaceAll('"', '');
    return breakpoint;
  },
  /**
   * Get Scroll Yes or No
   */
  getScrollYN(el) {
    if (el.scrollWidth > el.clientWidth) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * Start Loading
   */
  startLoading(msg, subMsg) {
    let wrapElmnt = document.querySelector('.spinner-wrap');

    if (wrapElmnt) {
      wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
    } else {
      wrapElmnt = document.createElement('div');
      wrapElmnt.classList.add('spinner-wrap');
      wrapElmnt.dataset.task = 1;
      let loadingElmnt = document.createElement('div');

      if (msg != null) {
        wrapElmnt.classList.add('long');
        loadingElmnt.classList.add('long-wrap');
        let img = document.createElement('div');
        img.classList.add('img-wrap');
        loadingElmnt.appendChild(img);
        let title = document.createElement('h4');
        title.innerHTML = msg;
        loadingElmnt.appendChild(title);
        if (subMsg != null) {
          let subTitle = document.createElement('p');
          subTitle.innerHTML = subMsg;
          loadingElmnt.appendChild(subTitle);
        }
      } else {
        wrapElmnt.classList.remove('long');
        loadingElmnt.classList.add('spinner-border');
        let span = document.createElement('span');
        span.classList.add('sr-only');
        span.innerHTML = 'Loading...';
        loadingElmnt.appendChild(span);
      }

      wrapElmnt.appendChild(loadingElmnt);
      let body = document.getElementsByTagName('body')[0];
      body.classList.add('overflow-hidden');
      body.appendChild(wrapElmnt);
    }
  },
  /**
   * End Loading
   */
  endLoading() {
    setTimeout(function () {
      let wrapElmnt = document.querySelector('.spinner-wrap');

      if (wrapElmnt) {
        let taskCount = wrapElmnt.dataset.task;

        if (taskCount > 1) {
          wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
        } else {
          wrapElmnt.style.opacity = 0;
          setTimeout(function () {
            wrapElmnt.remove();
          }, 300);
          let body = document.getElementsByTagName('body')[0];
          body.classList.remove('overflow-hidden');
        }
      }
    }, 300);
  },
  /**
   * Ajax
   * ex) Utils.request('GET', 'test.json', null, (data) => { console.log(data) });
   * @param {String} type
   * @param {String} url
   * @param data
   * @param {Function} func
   */
  request(type, url, data, func) {
    $.ajax({
      type,
      url,
      data,
      success: (data) => {
        func(data);
      },
      error: (xhr, status, error) => {
        console.log(`status: ${status}\nmessage: ${error}`);
      },
    });
  },
  /**
   * Number to KRW format
   * ex) 1000000 -> 1,000,000
   * @param {Number} value
   * @returns {String}
   */
  numberFormatter(value) {
    if (value != '' && value != null && typeof value == 'number') {
      value = String(value)
        .replace(/[^\d]+/g, '')
        .replace(/(^0+)/, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    } else {
      value = 0;
    }
    return value === '' ? 'NaN' : value;
  },
  /**
   * Get input[type=file] detail
   * @param {Element} elmnt
   * @returns {Object}
   */
  getFileDetail(elmnt) {
    //파일 경로.
    let filePath = elmnt.value;
    //전체경로를 \ 나눔.
    let filePathSplit = filePath.split('\\');
    // 파일 전체명
    let originalFileName = filePathSplit[filePathSplit.length - 1];
    //파일확장자 앞 .의 index
    let lastDot = originalFileName.lastIndexOf('.');
    //파일명 : .으로 나눈 앞부분
    let fileName = originalFileName.substring(0, lastDot);
    //파일 확장자 : .으로 나눈 뒷부분
    let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length);
    //파일 크기
    let fileSize = filePath != '' ? elmnt.files[0].size : null;

    let object = {
      originalName: originalFileName,
      name: fileName,
      ext: fileExt,
      size: fileSize,
    };

    return object;
  },
  /**
   * Byte to size
   * return ex) 5 GB
   * @param {Number} byte
   * @returns {String}
   */
  byteFormatter(byte) {
    let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (byte == 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
    return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  /**
   * Set date format
   * @param {String} date
   * @returns {Object}
   */
  dateFormatter(date) {
    if ((date == '' || date == null) && typeof date == 'string') {
      return '';
    }
    const addZero = (num, digits) => {
      let zero = '';
      num = num.toString();

      if (num.length < digits) {
        for (let i = 0; i < digits - num.length; i++) {
          zero += '0';
        }
      }
      return zero + num;
    };
    let newDate = new Date(date);

    let yyyy = newDate.getFullYear();
    let mm = addZero(newDate.getMonth() + 1, 2);
    let m = newDate.getMonth() + 1;
    let dd = addZero(newDate.getDate(), 2);
    let d = newDate.getDate();

    let object = {
      slash: yyyy + '/' + mm + '/' + dd,
      dot: yyyy + '.' + mm + '.' + dd,
      dash: yyyy + '-' + mm + '-' + dd,
      word: yyyy + '년 ' + m + '월 ' + d + '일',
    };

    return object;
  },
};

/**
 * 프로젝트 관리 함수
 */
const ProjectUtils = {
  _isInit: false,
  _isReset: false,
  _currentStep: 1,
  subProject: {
    _total: 0,
    _pageSize: 1,
    _totalPage: 0,
    _currentPage: 1,
    selectItems: [],
  },
  ontology: {
    _isSelectSearch: false,
    _isInputSearch: false,
    _total: 0,
    _pageSize: 1,
    _totalPage: 0,
    _currentPage: 1,
    standard: {
      _total: 0,
      selectItems: [],
    },
    custom: {
      _total: 0,
      selectItems: [],
    },
  },
  init() {
    const $dataSeletContainer = $('.data-select-container');
    const $dataProgressBar = $dataSeletContainer.find('.progress-bar');
    const $dataTotal = $dataSeletContainer.find('.data-select-item.sub-project .total-count');
    const $dataSelect = $dataSeletContainer.find('.data-select-item.sub-project .select-count');
    const $standardTotal = $dataSeletContainer.find('.data-select-item.standard-ontology .total-count');
    const $standardSelect = $dataSeletContainer.find('.data-select-item.standard-ontology .select-count');
    const $customTotal = $dataSeletContainer.find('.data-select-item.custom-ontology .total-count');
    const $customSelect = $dataSeletContainer.find('.data-select-item.custom-ontology .select-count');

    // step 초기화
    ProjectUtils._resetStep(ProjectUtils._currentStep);

    if (ProjectUtils._currentStep == 1) {
      // 서브 프로젝트 section
      const $subProjectList = $('.sub-project-list');
      const $subProjectItems = $subProjectList.find('.sub-project-item');
      const $total = $subProjectList.closest('section').find('.step-count .total');
      const $btnSubProjectMore = $subProjectList.next('.btn-more');
      $dataProgressBar.css('width', '33.3%');
      ProjectUtils.subProject._total = $subProjectItems.length;
      ProjectUtils.subProject._pageSize = $('.sub-project-list').attr('data-page-size');
      ProjectUtils.subProject._totalPage = Math.ceil(ProjectUtils.subProject._total / ProjectUtils.subProject._pageSize);
      ProjectUtils.subProject._currentPage = 1;
      if (ProjectUtils.subProject._currentPage < ProjectUtils.subProject._totalPage) {
        $btnSubProjectMore.css('display', 'block');
        $btnSubProjectMore.find('.current-page').text(ProjectUtils.subProject._currentPage);
        $btnSubProjectMore.find('.total-page').text(ProjectUtils.subProject._totalPage);
      } else {
        $btnSubProjectMore.css('display', 'none');
      }
      $total.text(ProjectUtils.subProject._total);
      $dataTotal.text(ProjectUtils.subProject._total);

      for (let i = 0; i < ProjectUtils.subProject._pageSize; i++) {
        $($subProjectItems[i]).addClass('view');
      }

      // 서브 프로젝트 선택
      $subProjectItems.find('input[type=checkbox]').off('change');
      $subProjectItems.find('input[type=checkbox]').on('change', function () {
        const $this = $(this);

        if ($(this).prop('checked')) {
          ProjectUtils.subProject.selectItems.push($(this).attr('data-id'));
        } else {
          ProjectUtils.subProject.selectItems = ProjectUtils.subProject.selectItems.filter(function (item) {
            return $this.attr('data-id') != item;
          });
        }
        if (ProjectUtils.subProject.selectItems.length > 0) {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', false);
          $dataSeletContainer.find('.data-select-item.sub-project').addClass('view');
        } else {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', true);
          $dataSeletContainer.find('.data-select-item.sub-project').removeClass('view');
        }

        $dataSelect.text(ProjectUtils.subProject.selectItems.length);
      });
      // 더보기
      $btnSubProjectMore.off('click');
      $btnSubProjectMore.on('click', function () {
        if (ProjectUtils.subProject._currentPage < ProjectUtils.subProject._totalPage) {
          ProjectUtils.subProject._currentPage++;
          if (ProjectUtils.subProject._currentPage >= ProjectUtils.subProject._totalPage) {
            $btnSubProjectMore.remove();
          } else {
            $btnSubProjectMore.find('.current-page').text(ProjectUtils.subProject._currentPage);
          }
          for (let i = (ProjectUtils.subProject._currentPage - 1) * ProjectUtils.subProject._pageSize; i < ProjectUtils.subProject._currentPage * ProjectUtils.subProject._pageSize; i++) {
            $($subProjectItems[i]).addClass('view');
          }
        }
      });
    } else if (ProjectUtils._currentStep == 2) {
      // 온톨로지 선택 section
      const $ontologySelectList = $('.ontology-select-list');
      $ontologySelectList.find('.empty').parent().remove();
      const $ontologySwitch = $('.ontology-switch input[type="checkbox"]');
      let $ontologySelectItems = $ontologySelectList.find('tbody tr');
      const $total = $ontologySelectList.closest('section').find('.step-count .total');
      const $btnSelectOntologyMore = $ontologySelectList.parent().next('.btn-more');
      const $standardOntologySelectItems = $ontologySelectList.find('tbody tr').filter(function () {
        return $(this).find('td.type').text() == '표준';
      });
      const $customOntologySelectItems = $ontologySelectList.find('tbody tr').filter(function () {
        return $(this).find('td.type').text() == '커스텀';
      });

      $dataProgressBar.css('width', '66.6%');
      $ontologySelectItems.removeClass('view');
      ProjectUtils.ontology.standard._total = $standardOntologySelectItems.length;
      ProjectUtils.ontology.custom._total = $customOntologySelectItems.length;

      if (ProjectUtils.ontology._isSelectSearch) {
        $ontologySelectItems = $ontologySelectItems.filter(function () {
          return $(this).hasClass('result');
        });
      } else {
        ProjectUtils.ontology._total = $ontologySelectItems.length;

        $standardTotal.text(ProjectUtils.ontology.standard._total);
        $customTotal.text(ProjectUtils.ontology.custom._total);
      }

      if ($ontologySwitch.prop('checked')) {
        $ontologySelectItems = $ontologySelectItems.filter(function () {
          return $(this).find('input[type="checkbox"]').prop('checked');
        });
      }

      ProjectUtils.ontology._total = $ontologySelectItems.length;

      $total.text(ProjectUtils.ontology._total);

      ProjectUtils.ontology._currentPage = 1;
      ProjectUtils.ontology._pageSize = $('.ontology-select-list').attr('data-page-size');
      ProjectUtils.ontology._totalPage = Math.ceil(ProjectUtils.ontology._total / ProjectUtils.ontology._pageSize);

      if (ProjectUtils.ontology._currentPage < ProjectUtils.ontology._totalPage) {
        $btnSelectOntologyMore.css('display', 'block');
        $btnSelectOntologyMore.find('.current-page').text(ProjectUtils.ontology._currentPage);
        $btnSelectOntologyMore.find('.total-page').text(ProjectUtils.ontology._totalPage);
      } else {
        $btnSelectOntologyMore.css('display', 'none');
      }

      if ($ontologySelectItems.length > 0) {
        for (let i = 0; i < ProjectUtils.ontology._pageSize; i++) {
          $($ontologySelectItems[i]).addClass('view');
        }
      } else {
        const src = typeof contextPath === 'undefined' ? 'images/img-empty@3x.png' : contextPath + '/resources/dist/images/img-empty@3x.png';
        $ontologySelectList.find('tbody').append(`
        <tr style="display: table-row">
          <td class="empty" colspan="${$ontologySelectList.find('thead th').length}">
            <img src="${src}" alt="데이터 없음">
            <p>
              검색 조건에 알맞는 데이터가 없습니다.<br>
              다른 검색 조건을 사용해보세요.
            </p>
          </td>
        </tr>
        `);
      }

      // 표준 온톨로지 선택
      $standardOntologySelectItems.find('input[type=checkbox]').off('change');
      $standardOntologySelectItems.find('input[type=checkbox]').on('change', function () {
        const $this = $(this);
        const $dataInputList = $(this).closest('td').find('input[type="hidden"]');

        if ($(this).prop('checked')) {
          let data = {
            id: $(this).attr('data-id'),
          };
          for (let i = 0; i < $dataInputList.length; i++) {
            data[$($dataInputList[i]).attr('name')] = $dataInputList[i].value;
          }
          ProjectUtils.ontology.standard.selectItems.push(data);
        } else {
          ProjectUtils.ontology.standard.selectItems = ProjectUtils.ontology.standard.selectItems.filter(function (item) {
            return $this.attr('data-id') != item.id;
          });
        }
        if (ProjectUtils.ontology.standard.selectItems.length + ProjectUtils.ontology.custom.selectItems.length > 0) {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', false);
          $dataSeletContainer.find('.data-select-item.standard-select').addClass('view');
        } else {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', true);
          $dataSeletContainer.find('.data-select-item.standard-select').removeClass('view');
        }

        $standardSelect.text(ProjectUtils.ontology.standard.selectItems.length);
      });
      // 커스텀 온톨로지 선택
      $customOntologySelectItems.find('input[type=checkbox]').off('change');
      $customOntologySelectItems.find('input[type=checkbox]').on('change', function () {
        const $this = $(this);
        const $dataInputList = $(this).closest('td').find('input[type="hidden"]');

        if ($(this).prop('checked')) {
          let data = {
            id: $(this).attr('data-id'),
          };
          for (let i = 0; i < $dataInputList.length; i++) {
            data[$($dataInputList[i]).attr('name')] = $dataInputList[i].value;
          }
          ProjectUtils.ontology.custom.selectItems.push(data);
        } else {
          ProjectUtils.ontology.custom.selectItems = ProjectUtils.ontology.custom.selectItems.filter(function (item) {
            return $this.attr('data-id') != item.id;
          });
        }
        if (ProjectUtils.ontology.standard.selectItems.length + ProjectUtils.ontology.custom.selectItems.length > 0) {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', false);
          $dataSeletContainer.find('.data-select-item.custom-select').addClass('view');
        } else {
          $dataSeletContainer.find('.btn-step-next').attr('disabled', true);
          $dataSeletContainer.find('.data-select-item.custom-select').removeClass('view');
        }
        $customSelect.text(ProjectUtils.ontology.custom.selectItems.length);
      });
      // 더보기
      $btnSelectOntologyMore.off('click');
      $btnSelectOntologyMore.on('click', function () {
        if (ProjectUtils.ontology._currentPage < ProjectUtils.ontology._totalPage) {
          ProjectUtils.ontology._currentPage++;
          if (ProjectUtils.ontology._currentPage >= ProjectUtils.ontology._totalPage) {
            $btnSelectOntologyMore.css('display', 'none');
          } else {
            $btnSelectOntologyMore.css('display', 'block');
            $btnSelectOntologyMore.find('.current-page').text(ProjectUtils.ontology._currentPage);
          }
          for (let i = (ProjectUtils.ontology._currentPage - 1) * ProjectUtils.ontology._pageSize; i < ProjectUtils.ontology._currentPage * ProjectUtils.ontology._pageSize; i++) {
            $($ontologySelectItems[i]).addClass('view');
          }
        }
      });
    } else if (ProjectUtils._currentStep == 3) {
      // 온톨로지 조회 조건 section
      const $ontologyInputList = $('.ontology-input-list');
      $ontologyInputList.find('.empty').parent().remove();
      let $ontologySelectItems = $ontologyInputList.find('tbody tr');
      const $total = $ontologySelectItems.closest('section').find('.step-count .total');

      $dataProgressBar.css('width', '100%');
      $ontologySelectItems.removeClass('view');

      if (ProjectUtils.ontology._isInputSearch) {
        $ontologySelectItems = $ontologySelectItems.filter(function () {
          return $(this).hasClass('result');
        });
      }

      ProjectUtils.ontology._total = $ontologySelectItems.length;

      $total.text(ProjectUtils.ontology._total);

      if ($ontologySelectItems.length > 0) {
        $ontologySelectItems.addClass('view');
      } else {
        $ontologyInputList.find('tbody').append(`
        <tr style="display: table-row">
          <td class="empty" colspan="${$ontologyInputList.find('thead th').length}">
            <img src="images/img-empty@3x.png" alt="데이터 없음">
            <p>
              검색 조건에 알맞는 데이터가 없습니다.<br>
              다른 검색 조건을 사용해보세요.
            </p>
          </td>
        </tr>
        `);
      }
    }

    if (ProjectUtils._currentStep < 4) {
      $('.popover-container').addClass('blank');
    }

    // 최초 init 했을경우
    if (!ProjectUtils._isInit) {
      ProjectUtils._isInit = true;

      $(document).on('click', '.btn-step-prev', function () {
        ProjectUtils.prevStep();
      });
      // $(document).on('click', '.btn-step-next', function () {
      //   if (ProjectUtils._currentStep != 1 && ProjectUtils._currentStep != 3) {
      //     ProjectUtils.nextStep();
      //   }
      // });
      $(document).on('click', '.btn-step-reset', function () {
        ProjectUtils._isReset = true;
        ProjectUtils._resetStep(1);
        ProjectUtils._resetStep(2);
        ProjectUtils._resetStep(3);
        ProjectUtils._isReset = false;

        ProjectUtils._currentStep = 2;
        ProjectUtils.prevStep();
      });
      $(document).on('input', '.step-table-search', function () {
        const $stepTable = $(this).closest('section').find('.step-table');
        const keyword = $(this).val().trim();

        const $stepTableData = $stepTable.find('.search-keyword');

        if (keyword != '') {
          if ($stepTable.hasClass('ontology-select-list')) {
            ProjectUtils.ontology._isSelectSearch = true;
          } else if ($stepTable.hasClass('ontology-input-list')) {
            ProjectUtils.ontology._isInputSearch = true;
          }

          const $stepTableSearchData = $stepTableData.filter(function () {
            return $(this).text().trim().includes(keyword);
          });
          $stepTableData.closest('tr').removeClass('result');

          if ($stepTableSearchData.length > 0) {
            $stepTableSearchData.closest('tr').addClass('result');
          }
        } else {
          if ($stepTable.hasClass('ontology-select-list')) {
            ProjectUtils.ontology._isSelectSearch = false;
          } else if ($stepTable.hasClass('ontology-input-list')) {
            ProjectUtils.ontology._isInputSearch = false;
          }
        }

        ProjectUtils.init();
      });
      $(document).on('change', '.ontology-switch input[type=checkbox]', function () {
        ProjectUtils.init();
      });
      $(document).on('click', '.step-table .btn-remove', function () {
        const target = $(this).closest('tr').attr('data-target');
        const $siblings = $(this).closest('tr').siblings();
        if ($siblings.length == 1) {
          $siblings.find('.btn-remove').attr('disabled', true);
        }
        $(`[data-id="${target}"]`).prop('checked', false).trigger('change');
        $(this).closest('tr').remove();

        ProjectUtils.init();
      });
    }
  },
  prevStep() {
    const $stepContainer = $('.step-container');

    if (ProjectUtils._currentStep > 1 && ProjectUtils._currentStep <= 4) {
      $('.data-select-container').removeClass('d-none');

      ProjectUtils._isReset = true;
      ProjectUtils._resetStep(ProjectUtils._currentStep);
      ProjectUtils._isReset = false;
      const $stepItems = $stepContainer
        .find('.step-detail')
        .eq(--ProjectUtils._currentStep - 1)
        .find('.step-item');
      $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target')).css('display', 'block');
      $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target'))
        .siblings('.step-detail')
        .css('display', 'none');
      // step 이동시 browser scroll 위치 조정
      $('html').scrollTop($('.list .tab-content').position().top - $('.layout-header').height());

      ProjectUtils.init();

      return true;
    } else {
      return false;
    }
  },
  nextStep() {
    const $stepContainer = $('.step-container');

    if (ProjectUtils._currentStep >= 1 && ProjectUtils._currentStep < 4) {
      $('.data-select-container').removeClass('d-none');

      if (ProjectUtils._currentStep == 1) {
        // 서브 프로젝트
        if (ProjectUtils.subProject.selectItems.length > 0) {
          const $stepItems = $stepContainer
            .find('.step-detail')
            .eq(++ProjectUtils._currentStep - 1)
            .find('.step-item');
          $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target')).css('display', 'block');
          $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target'))
            .siblings('.step-detail')
            .css('display', 'none');
          // step 이동시 browser scroll 위치 조정
          $('html').scrollTop($('.list .tab-content').position().top - $('.layout-header').height());
        } else {
          toastr.error('서브 프로젝트를 선택해주세요.');
        }
      } else if (ProjectUtils._currentStep == 2) {
        // 온톨로지 선택
        if (ProjectUtils.ontology.standard.selectItems.length + ProjectUtils.ontology.custom.selectItems.length > 0) {
          const $stepItems = $stepContainer
            .find('.step-detail')
            .eq(++ProjectUtils._currentStep - 1)
            .find('.step-item');
          $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target')).css('display', 'block');
          $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target'))
            .siblings('.step-detail')
            .css('display', 'none');
          // step 이동시 browser scroll 위치 조정
          $('html').scrollTop($('.list .tab-content').position().top - $('.layout-header').height());

          // Set Ontology Input Table
          ProjectUtils._createInputList();
        } else {
          toastr.error('온톨로지를 선택해주세요.');
        }
      } else if (ProjectUtils._currentStep == 3) {
        // 온톨로지 조회 조건 선택
        const $stepItems = $stepContainer
          .find('.step-detail')
          .eq(++ProjectUtils._currentStep - 1)
          .find('.step-item');
        $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target')).css('display', 'block');
        $($stepItems.eq(ProjectUtils._currentStep - 1).attr('data-target'))
          .siblings('.step-detail')
          .css('display', 'none');
        // step 이동시 browser scroll 위치 조정
        $('html').scrollTop($('.list .tab-content').position().top - $('.layout-header').height());

        $('.data-select-container').addClass('d-none');
        $('.popover-container').removeClass('blank');
      }

      ProjectUtils.init();

      return true;
    } else {
      return false;
    }
  },
  getData() {
    let data = {
      subProject: ProjectUtils.subProject.selectItems,
      ontology: {
        standard: ProjectUtils.ontology.standard.selectItems,
        custom: ProjectUtils.ontology.custom.selectItems,
      },
    };
    for (let i = 0; i < data.ontology.standard.length; i++) {
      const $target = $(`tr[data-target="${data.ontology.standard[i].id}"]`);
      const $filters = $target.find('input:not(.select2-search__field, [type="number"], .datepicker), select').eq(0);
      const $filterPair = $target.find('input[type="number"], input.datepicker');

      // 2024/02/20 filters가 무조건 하나씩만 들어감 그에따른 수정
      let filters = [];
      for (let j = 0; j < $filters.length; j++) {
        let filter = {};
        if ($($filters[j]).prop('tagName') == 'SELECT') {
          const $filterOptions = $($filters[j]).find('option:selected');
          filter[$($filters[j]).attr('name')] = [];
          for (let k = 0; k < $filterOptions.length; k++) {
            let option = {};
            option[$($filterOptions[k]).val()] = $($filterOptions[k]).text();
            filter[$($filters[j]).attr('name')].push(option);
          }
        } else {
          filter[$($filters[j]).attr('name')] = $($filters[j]).val();
        }
        filters.push(filter);
      }
      if ($filterPair.length > 0) {
        let filter = {};
        filter[$($filterPair[0]).attr('name')] = $($filterPair[0]).val();
        filter[$($filterPair[1]).attr('name')] = $($filterPair[1]).val();

        filters.push(filter);
      }
      data.ontology.standard[i]['filter'] = filters;
    }
    for (let i = 0; i < data.ontology.custom.length; i++) {
      const $target = $(`tr[data-target="${data.ontology.custom[i].id}"]`);
      const $filters = $target.find('input:not(.select2-search__field, [type="number"], .datepicker), select');
      const $filterPair = $target.find('input[type="number"], input.datepicker');
      let filters = [];
      for (let j = 0; j < $filters.length; j++) {
        let filter = {};
        if ($($filters[j]).prop('tagName') == 'SELECT') {
          const $filterOptions = $($filters[j]).find('option:selected');
          filter[$($filters[j]).attr('name')] = [];
          for (let k = 0; k < $filterOptions.length; k++) {
            let option = {};
            option[$($filterOptions[k]).val()] = $($filterOptions[k]).text();
            filter[$($filters[j]).attr('name')].push(option);
          }
        } else {
          filter[$($filters[j]).attr('name')] = $($filters[j]).val();
        }
        filters.push(filter);
      }
      if ($filterPair.length > 0) {
        let filter = {};
        filter[$($filterPair[0]).attr('name')] = $($filterPair[0]).val();
        filter[$($filterPair[1]).attr('name')] = $($filterPair[1]).val();
        filters.push(filter);
      }
      data.ontology.custom[i]['filter'] = filters;
    }
    return data;
  },
  _resetStep(step) {
    const $dataSeletContainer = $('.data-select-container');
    const $dataTotal = $dataSeletContainer.find('.data-select-item.sub-project .total-count');
    const $dataSelect = $dataSeletContainer.find('.data-select-item.sub-project .select-count');
    const $standardTotal = $dataSeletContainer.find('.data-select-item.standard-ontology .total-count');
    const $standardSelect = $dataSeletContainer.find('.data-select-item.standard-ontology .select-count');
    const $customTotal = $dataSeletContainer.find('.data-select-item.custom-ontology .total-count');
    const $customSelect = $dataSeletContainer.find('.data-select-item.custom-ontology .select-count');

    if (step == 1) {
      const $subProjectList = $('.sub-project-list');

      $dataSeletContainer.find('.btn-step-prev').addClass('d-none');
      $dataSeletContainer.find('.btn-step-next').text('다음으로');

      if (ProjectUtils.subProject.selectItems.length == 0) {
        $dataSeletContainer.find('.btn-step-next').attr('disabled', true);
      } else {
        $dataSeletContainer.find('.btn-step-next').attr('disabled', false);
      }
      if (ProjectUtils._isReset) {
        ProjectUtils.subProject._total = 0;
        ProjectUtils.subProject._pageSize = 1;
        ProjectUtils.subProject._totalPage = 0;
        ProjectUtils.ontology._isSelectSearch = false;
        $subProjectList.find('input[type=checkbox]').prop('checked', false).trigger('change');
        $dataTotal.text(ProjectUtils.subProject._total);
        $dataSelect.parent().parent().addClass('d-none');
      } else {
        $dataSelect.parent().parent().removeClass('d-none');
      }
    } else if (step == 2) {
      const $ontologySelectList = $('.ontology-select-list');
      const $ontologySelectSearch = $ontologySelectList.closest('section').find('.step-table-search');

      $dataSeletContainer.find('.btn-step-prev').removeClass('d-none');
      $dataSeletContainer.find('.btn-step-next').text('다음으로');
      if (ProjectUtils.ontology.standard.selectItems.length + ProjectUtils.ontology.custom.selectItems.length == 0) {
        $dataSeletContainer.find('.btn-step-next').attr('disabled', true);
      } else {
        $dataSeletContainer.find('.btn-step-next').attr('disabled', false);
      }
      if (ProjectUtils._isReset) {
        ProjectUtils.ontology._total = 0;
        ProjectUtils.ontology._pageSize = 1;
        ProjectUtils.ontology._totalPage = 0;
        ProjectUtils.ontology._currentPage = 1;
        ProjectUtils.ontology.custom._total = 0;
        ProjectUtils.ontology.standard._total = 0;
        ProjectUtils.ontology._isSelectSearch = false;
        $ontologySelectSearch.val('');
        $('.ontology-switch').find('input[type=checkbox]').prop('checked', false);
        $ontologySelectList.find('input[type=checkbox]').prop('checked', false).trigger('change');
        $standardTotal.text(ProjectUtils.ontology.standard._total);
        $standardSelect.parent().parent().addClass('d-none');
        $customTotal.text(ProjectUtils.ontology.custom._total);
        $customSelect.parent().parent().addClass('d-none');
      } else {
        $standardSelect.parent().parent().removeClass('d-none');
        $customSelect.parent().parent().removeClass('d-none');
      }
    } else if (step == 3) {
      const $ontologyInputList = $('.ontology-input-list');
      const $ontologyInputSearch = $ontologyInputList.closest('section').find('.step-table-search');

      $dataSeletContainer.find('.btn-step-prev').removeClass('d-none');
      $dataSeletContainer.find('.btn-step-next').text('조회하기');
      if (ProjectUtils._isReset) {
        ProjectUtils.ontology._isInputSearch = false;
        $ontologyInputSearch.val('');
        $ontologyInputList.find('input').val('').trigger('change');
        $ontologyInputList.find('select').val('').trigger('change');
      }
    }
  },
  _createInputList() {
    const $ontologySelectItems = $('.ontology-select-list').find('input[type="checkbox"]:checked').closest('tr');
    const $ontologyInputContainer = $('.ontology-input-container');
    let ontologyInputList = `
    <table class="table ta-table step-table ontology-input-list">
      <caption class="sr-only">
        온톨로지 조회 조건 입력
      </caption>
      <colgroup>
        <col />
        <col style="width: 176px" />
        <col style="width: 358px" />
        <col style="width: 108px" />
      </colgroup>
      <thead>
        <tr>
          <th class="text-center">온톨로지</th>
          <th class="text-center">데이터 타입(단위)</th>
          <th class="text-center">조회 조건 입력</th>
          <th class="text-center">제외</th>
        </tr>
      </thead>
      <tbody>
      `;
    for (let i = 0; i < $ontologySelectItems.length; i++) {
      const inputType = $($ontologySelectItems[i]).find('.input-type').text();
      ontologyInputList += `
      <tr data-target="${$($ontologySelectItems[i]).find('input[type="checkbox"]').attr('data-id')}">
        <td>
          <div class="icon-wrap">
      `;
      if (inputType.includes('수치형')) {
        ontologyInputList += `
            <div class="icon number-type">
              <i class="material-icons">square_foot</i>
            </div>
        `;
      } else if (inputType.includes('날짜형')) {
        ontologyInputList += `
            <div class="icon date-type">
              <i class="material-icons">event_available</i>
            </div>
        `;
      } else if (inputType.includes('항목형')) {
        ontologyInputList += `
          <div class="icon item-type">
            <i class="material-icons">fact_check</i>
          </div>
        `;
      } else if (inputType.includes('문자형') || inputType.includes('None Type')) {
        ontologyInputList += `
            <div class="icon keyword-type">
              <i class="material-icons">keyboard</i>
            </div>
        `;
      }
      ontologyInputList += `
            <div class="desc">
              <h5 class="search-keyword">${$($ontologySelectItems[i]).find('.name').text()}</h5>
              <p class="search-keyword" title="${$($ontologySelectItems[i]).find('.name-type').text()}">${$($ontologySelectItems[i]).find('.name-type').text()}</p>
            </div>
          </div>
        </td>
        <td class="text-center">${$($ontologySelectItems[i]).find('.type').text()}</td>
        <td class="text-center">
      `;
      if (inputType.includes('수치형')) {
        ontologyInputList += `
        <div class="ta-number-pair-wrap d-flex">
          <div class="flex-grow-1">
            <input type="number" name="min" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_min" class="form-control ta-form text-center" min="0" placeholder="최솟값 입력"/>
          </div>
          <div class="flex-grow-1">
            <input type="number" name="max" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_max" class="form-control ta-form text-center" min="0" placeholder="최댓값 입력"/>
          </div>
        </div>
        `;
      } else if (inputType.includes('날짜형')) {
        ontologyInputList += `
        <div class="ta-date-pair-wrap d-flex">
          <div class="start flex-grow-1">
            <input type="text" name="min" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_min" class="form-control ta-form datepicker" autocomplete="off" placeholder="시작일자 선택"/>
            <i class="material-icons">calendar_month</i>
          </div>
          <div class="end flex-grow-1">
            <input type="text" name="max" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_max" class="form-control ta-form datepicker" autocomplete="off" placeholder="종료일자 선택"/>
            <i class="material-icons">calendar_month</i>
          </div>
        </div>
        `;
      } else if (inputType.includes('항목형')) {
        const $items = $($ontologySelectItems[i]).find('select option');
        ontologyInputList += `
        <select name="item" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_item" class="select2" data-placeholder="항목 선택" multiple="multiple">
        `;
        for (let j = 0; j < $items.length; j++) {
          ontologyInputList += `
          <option value="${$($items[j]).val()}">${$($items[j]).text()}</option>
          `;
        }
        ontologyInputList += `
        </select>
        `;
      } else if (inputType.includes('문자형') || inputType.includes('None Type')) {
        ontologyInputList += `
        <input type="text" name="keyword" id="${$($ontologySelectItems[i])
            .find('input[type="checkbox"]')
            .attr('data-id')}_keyword" class="form-control ta-form" placeholder="검색 키워드 입력">
        `;
      }
      ontologyInputList += `
        </td>
        <td class="text-center">
          <button type="button" class="btn-remove" ${$ontologySelectItems.length == 1 ? 'disabled' : ''}>제외하기</button>
        </td>
      </tr>
      `;
    }

    ontologyInputList += `
      </tbody>
    </table>
    `;
    $ontologyInputContainer.find('.ontology-input-list').remove();
    $ontologyInputContainer.append(ontologyInputList);

    Utils.setSelect2();
    Utils.setDatepicker();
  },
};

function setCommonEvent() {
  const $header = $('.layout-header');
  const $nav = $header.find('.navbar-collapse');
  const $btnMobileMenu = $('.navbar-nav.mobile a');
  const $mobileMenu = $('.navbar.mobile');
  const $navItem = $('.layout-header .navbar-nav .nav-item');
  const $navLink = $('.navbar-brand, .layout-header .navbar-nav li > a');

  // #region hover event
  // TODO: 임시코드 추후 Utils.getBreakpoint() != 'lg' 제거
  let isMoved = false;
  let timeOut = null;
  $nav.on('mouseenter focusin', function () {
    if (!isMoved) {
      if (Utils.getBreakpoint() != 'sm' && Utils.getBreakpoint() != 'md' && Utils.getBreakpoint() != 'lg') {
        isMoved = true;
        clearTimeout(timeOut);
        $header.addClass('active');
        $header.find('.dropdown.show .btn').trigger('blur');
        $header.find('.dropdown.show, .dropdown .show').removeClass('show');
        let $subItem = $(this).find('ul ul');
        let $maxSubItem = $subItem;
        $subItem.filter(function (index, el) {
          if (index > 0) {
            if ($(el).outerHeight() > $maxSubItem.outerHeight()) {
              $maxSubItem = $(el);
            }
          }
        });
        let fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        let maxHeight = $maxSubItem.height() / fontSize + 8 + 'rem';
        $header.stop().animate({ height: maxHeight }, 300);
        setTimeout(function () {
          isMoved = false;
        }, 300);
      }
    }
  });
  $nav.on('mouseleave', function () {
    if (Utils.getBreakpoint() != 'sm' && Utils.getBreakpoint() != 'md' && Utils.getBreakpoint() != 'lg') {
      isMoved = false;
      $header.stop().animate({ height: '5rem' }, 300);
      clearTimeout(timeOut);
      timeOut = setTimeout(function () {
        $header.removeClass('active');
      }, 250);
    }
  });
  // #endregion hover event
  // #region mobile menu event
  $btnMobileMenu.on('click', () => {
    $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
    $mobileMenu.addClass('active');
    $mobileMenu.addClass('visible');
    setTimeout(() => {
      $('body').addClass('modal-open mobile');
      $('.header-backdrop').addClass('show');
    }, 100);
  });
  $(document).on('click', '.header-backdrop, .btn-navbar-close', () => {
    $('.header-backdrop').removeClass('show');
    $mobileMenu.removeClass('active');
    $('body').removeClass('modal-open mobile');
    setTimeout(() => {
      $('.header-backdrop').remove();
      $mobileMenu.removeClass('visible');
    }, 300);
  });
  // #endregion mobile menu event
  // #region tab event
  $navItem.on('focusin', function () {
    removeMenuActive();
    $(this).addClass('active');
  });
  $navItem.on('click', function () {
    $navItem.removeClass('active-only');
    $(this).addClass('active-only');
    $(this).removeClass('active');
  });
  $navLink.on('focusin', function () {
    removeMenuActive();
  });
  $(document).on('mousedown', function () {
    removeMenuActive();
  });
  $('.contents-skip, .layout-main, .layout-footer').on('focusin', function () {
    removeMenuActive();
    if ($header.hasClass('active')) {
      if (Utils.getBreakpoint() != 'sm' && Utils.getBreakpoint() != 'md') {
        $header.animate({ height: '5rem' }, 300);
        clearTimeout(timeOut);
        timeOut = setTimeout(function () {
          $header.removeClass('active');
        }, 250);
      }
    }
  });
  function removeMenuActive() {
    $('.layout-header .navbar-nav .nav-item').removeClass('active');
  }
  // #endregion tab event
  // #region password event
  $(document).on('click', '.btn-password-view', function () {
    const $input = $(this).parents('.password-container').find('input');

    if ($(this).text() == 'visibility_off') {
      $input.attr('type', 'text');
      $(this).find('i').text('visibility');
    } else {
      $input.attr('type', 'password');
      $(this).find('i').text('visibility_off');
    }
  });
  // #endregion password event
  // #region login event
  $(document).on('click', '.btn-step', function () {
    const $container = $(this).closest('.login-main');
    const $containerInner = $(this).closest('.login-main-inner');
    const $currentSection = $(this).closest('section');
    const $target = $($(this).attr('data-target'));

    $target.css('display', 'block');
    if ($target.index() > 0) {
      $container.find('h1').css('display', 'none');
    } else {
      $container.find('h1').css('display', 'block');
    }
    if ($target.index() > $currentSection.index()) {
      $containerInner.css('transform', `translateX(-${$container.outerWidth()}px)`);
    } else {
      $containerInner.addClass('no-animate');
      $containerInner.css('transform', `translateX(-${$container.outerWidth()}px)`);
      setTimeout(function () {
        $containerInner.removeClass('no-animate');
        $containerInner.css('transform', 'translateX(0)');
      }, 10);
    }
    setTimeout(function () {
      $containerInner.addClass('no-animate');
      $currentSection.css('display', 'none');
      $containerInner.css('transform', 'translateX(0)');
      setTimeout(function () {
        $containerInner.removeClass('no-animate');
      }, 10);
    }, 300);
  });
  // #endregion login event
  // #region file event
  $(document).on('change', 'input[type="file"].custom-file', function () {
    let $fileContainer = $(this).closest('.file-container');
    let $fileCopy = $(this).clone();
    $fileCopy.removeAttr('id');
    $fileCopy.removeAttr('class');
    $fileCopy.attr('name', $fileCopy.attr('data-name'));
    $fileCopy.removeAttr('data-name');
    let newFile = `
    <div class="file-wrap">
      <i class="material-icons mr-6">insert_drive_file</i>
      <p>${Utils.getFileDetail($fileCopy[0]).name}</p>
      <span class="mr-6">.${Utils.getFileDetail($fileCopy[0]).ext}</span>
      <button type="button" class="btn-icon btn-remove"><i class="material-icons">cancel</i></button>
    </div>
    `;

    $fileContainer.append(newFile);
    $fileContainer.find('.file-wrap').last().append($fileCopy);
  });
  $(document).on('click', '.file-container .btn-remove', function () {
    $(this).parent().remove();
  });
  // #endregion file event
  // #region accordion buton bubbling event
  $(document).on('click', '.accordion .btn .btn-inner', function (e) {
    e.stopPropagation();
  });
  // #endregion accodion buton bubbling event
  // #region image list modal event
  $(document).on('click', '.img-list .img-link', function () {
    // 이미 존재할 경우 삭제
    $('#imgListModal').remove();

    const $imgList = $(this).closest('.img-list');
    let modal = `
    <div class="modal img-modal fade" id="imgListModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <div class="d-flex flex-column w-100">
                <h3>${$imgList.attr('data-title')}</h3>
                <p class="mt-4">${$imgList.attr('data-sub-title')}</p>
              </div>
              <button type="button" class="btn btn-primary btn-icon btn-img-download"><i class="material-icons mr-6">download</i>다운로드</button>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="modal-body pb-40">
            <div id="imgListControls" class="carousel slide" data-ride="carousel" data-interval="0">
              <div class="carousel-inner">`;

    for (let i = 0; i < $imgList.find('.img-item').length; i++) {
      modal += `
      <div class="carousel-item ${$(this).closest('.img-item').index() == i ? 'active' : ''}">
        <img src="${$imgList.find('.img-item').eq(i).find('img').attr('src')}"
             data-fileNm="${$imgList.find('.img-item').eq(i).find('img').attr('data-fileNm')}"
             data-fileMask="${$imgList.find('.img-item').eq(i).find('img').attr('data-fileMask')}"
             alt="${$imgList.find('.img-item').eq(i).find('img').attr('alt')}">
      </div>`;
    }

    modal += `</div>
              <a class="carousel-control-prev" href="#imgListControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#imgListControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
    $('body').append(modal);
    $('#imgListModal').modal('show');
  });
  $(document).on('click', '.modal .btn-img-download', function () {
    const $parent = $(this).closest('.modal');
    const $currentImg = $parent.find('.carousel .carousel-item.active img');
    const link = document.createElement('a');
    link.id = 'carouselImgLink';
    link.href = $currentImg.attr('data-fileMask');
    link.download = $currentImg.attr('data-fileNm');
    $('body').append(link);
    link.click();
    $('#carouselImgLink').remove();
  });
  // #endregion image list modal event
  // #region dropdown fixed event
  // $(document).on('show.bs.dropdown', '.dropdown', function() {
  //   const $dropdownMenu = $(this).find('.dropdown-menu');
  //   const parentWidth = $(this).width();
  //   const parentHeight = $(this).height();
  //   const parentTop = $(this).offset().top - $(window).scrollTop();
  //   const parentLeft = $(this).offset().left;

  //   $dropdownMenu.css('opacity', 0);

  //   setTimeout(function() {
  //     $dropdownMenu.css({
  //       'position': 'fixed',
  //       'top': parentHeight + parentTop,
  //       'left': parentLeft,
  //       'transform': 'none',
  //       'opacity': 1,
  //     });
  //   }, 0);
  // });
  // #endregion dropdown fixed event
  // #region krajee custom
  $(document).on('click', '.kv-file-remove', function () {
    const $parent = $(this).closest('.file-input');
    const $filePreviewList = $parent.find('.kv-preview-thumb');
    const $fileCaption = $parent.find('.file-caption');

    if ($filePreviewList.length == 1) {
      setTimeout(function () {
        $fileCaption.removeClass('icon-visible');
      }, 600);
    }
  });
  $(document).on('change', '.file-input .file-caption-main .btn-file input[type="file"]', function () {
    const $parent = $(this).closest('.file-input');
    const $filePreviewList = $parent.find('.kv-preview-thumb');
    const $fileCaption = $parent.find('.file-caption');

    if ($filePreviewList.length > 0) {
      $fileCaption.addClass('icon-visible');
    }
  });
  // #endregion krajee custom
  // #region number list event
  $(document).on('click', '.btn-code-request', function () {
    const $codeInput = $(this).parent().prev().find('input');
    const $target = $($(this).attr('data-target'));

    $target.find('.target-email').html($codeInput.val());

    Utils.setCodeTimer($(this).attr('data-target'));
  });
  $(document).on('click', '.btn-step[data-target="#step_1"]', function () {
    $(window).off('beforeunload');
  });
  $(document).on('input', '.number-list .number-item input', function (e) {
    const inputData = e.originalEvent.data;
    const $numberItem = $(this).closest('.number-item');

    if (inputData) {
      let isValid = /[0-9]/g.test(inputData);

      if (isValid) {
        $(this).val(inputData);
        $numberItem.next().find('input').trigger('focus');
      } else {
        $(this).val('');
      }
    }
  });
  $(document).on('keydown', '.number-list .number-item input', function (e) {
    e.stopPropagation();

    const $numberItem = $(this).closest('.number-item');

    if (e.originalEvent.code == 'ArrowLeft') {
      let $prevInput = $numberItem.prev().find('input');
      setTimeout(function () {
        $prevInput.trigger('select');
      }, 0);
    } else if (e.originalEvent.code == 'ArrowRight') {
      let $nextInput = $numberItem.next().find('input');
      setTimeout(function () {
        $nextInput.trigger('select');
      }, 0);
    }
  });
  // #endregion number list event
}

// tui-grid checkbox custom class
class CheckboxRenderer {
  constructor(props) {
    const { grid, rowKey } = props;

    const wrap = document.createElement('div');
    wrap.className = 'ta-checkbox-wrap';

    const hiddenInput = document.createElement('input');
    hiddenInput.className = 'sr-only each';
    hiddenInput.id = String(rowKey);

    const label = document.createElement('label');
    label.innerHTML = '&nbsp;';
    label.setAttribute('for', String(rowKey));

    wrap.appendChild(hiddenInput);
    wrap.appendChild(label);

    hiddenInput.type = 'checkbox';
    hiddenInput.addEventListener('change', (e) => {
      if (hiddenInput.checked) {
        grid.check(rowKey);
      } else {
        grid.uncheck(rowKey);
      }
    });

    this.el = wrap;
    this.render(props);
  }

  getElement() {
    return this.el;
  }

  render(props) {
    const hiddenInput = this.el.querySelector('.sr-only');
    const checked = Boolean(props.value);

    hiddenInput.checked = checked;
  }
}

class CustomDatepickerEditor {
  constructor(props) {
    const el = document.createElement('input');

    el.type = 'text';
    el.className = 'form-control ta-form datepicker';
    el.value = String(props.value);

    this.el = el;
  }

  getElement() {
    return this.el;
  }

  getValue() {
    return this.el.value;
  }

  mounted() {
    $(this.el).wrap('<div class="ta-date-wrap"></div>');
    $(this.el).parent().append('<i class="material-icons">calendar_month</i>');
    Utils.setDatepicker();
    this.el.select();
  }
}
